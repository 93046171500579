<template>
  <div class="flex flex-col gap-6">
    <div v-if="getOnlyAndRules?.length" class="flex max-w-2xl flex-col gap-2">
      <span class="text-body-2">Leads that match ALL of these conditions:</span>
      <div class="flex flex-row flex-wrap gap-3">
        <div v-for="(rule, index) in getOnlyAndRules" :key="index" class="flex items-center gap-3">
          <span :class="{ hidden: index === 0 }" class="text-table-header text-black-40">AND</span>
          <UiTagSecondary :id="`tag_${index}`" right-icon="" class="[&>div]:!h-6 [&>div]:!py-1">
            <template #content>
              {{ PARAMETERS_NAMES_MAP.get(rule.rule_name) }} {{ rule.rule_operator }}
              <span class="text-subhead-3">{{ rule.rule_value }}</span>
            </template>
          </UiTagSecondary>
        </div>
      </div>
    </div>
    <div v-if="getOnlyOrRules?.length" class="flex max-w-2xl flex-col gap-2">
      <span class="text-body-2"
        >{{ getOnlyAndRules?.length ? 'and' : 'Leads that' }} match ANY of these conditions:</span
      >
      <div class="flex flex-row flex-wrap gap-3">
        <div v-for="(rule, index) in getOnlyOrRules" :key="index" class="flex items-center gap-3">
          <span :class="{ hidden: index === 0 }" class="text-table-header text-black-40">OR</span>
          <UiTagSecondary :id="`tag_${index}`" right-icon="" class="[&>div]:!h-6 [&>div]:!py-1">
            <template #content>
              {{ PARAMETERS_NAMES_MAP.get(rule.rule_name) }} {{ rule.rule_operator }}
              <span class="text-subhead-3">{{ rule.rule_value }}</span>
            </template>
          </UiTagSecondary>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PARAMETERS_NAMES_MAP } from '~/constants'
import type { MappingRule } from '@/types'

type Props = {
  rules: MappingRule[]
}
const props = defineProps<Props>()

const getOnlyAndRules = computed(() => props.rules?.filter((r: MappingRule) => r.union_operator === 'and'))

const getOnlyOrRules = computed(() => props.rules?.filter((r: MappingRule) => r.union_operator === 'or'))
</script>

<style scoped></style>
